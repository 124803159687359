import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0055 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="所定労働時間に満たない！？その場合の賃金計算方法は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.07.27</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働時間とは、「使用者の指揮命令下に置かれた時間」と定義されていますが、定義とは別に、実務上の労働時間には大きく分けて2つの労働時間が存在します。１つは所定労働時間であり、もう１つは法定労働時間があります。今回は２つの労働時間の違いと所定労働時間に満たない場合の賃金計算方法や対策を解説します。{' '}
      </p>
      <img src="/images/column/details/c0055.jpg" alt="キンクラコラム画像55" />
      <h1 id="toc1">所定労働時間と法定労働時間の違い</h1>
      <p>
        まず、<span>所定労働時間とは会社が定めた労働時間となります。</span>
        例えば9時が始業時刻とし、17時が終業時刻の会社を例にします。仮に休憩時間が12時から13時とすると、「労働時間」としては7時間となります。前提として、あくまで拘束時間は9時から17時の8時間となりますが、12時から13時の休憩時間は労働時間としてカウントしません。ゆえに、
        <span>
          拘束時間から休憩時間を引いた7時間がこの会社の「働くべき時間」ということになり、このような時間を「所定労働時間」と呼びます。
        </span>
        <br />
        <br />
        次に法定労働時間とは、労働基準法第32条により1日に働くことができる時間の上限は8時間とされています。もちろん、繁忙期などは8時間を超えて働くこともあるのでしょうが、その場合は会社の就業規則に業務上の都合により1日8時間を超えて働くことを命じる場合がある旨の定めと、所轄労働基準監督署に「36協定」の届出をすることで、労働基準法上の罰則が適用されなくなります。これらの
        <span>
          「1日8時間」という法律上の働くことができる上限を「法定労働時間」と呼びます。
        </span>
        <br />
        <br />
        会社によっては所定労働時間と法定労働時間が同じというケースもあります。法定労働時間は法律によって決められていますので、会社の裁量で決められるものではありません。他方、所定労働時間は会社の裁量で決めることができますので、法定労働時間と同じ1日8時間働いてもらうというルールを決めることは何ら問題ありません。
      </p>

      <h1 id="toc2">所定労働時間に満たない場合の賃金計算方法</h1>
      <p>
        働き方の多様化や、諸般の事由により（例えば遅刻や早退）、特定の従業員の労働時間が本来働くべき時間数である所定労働時間に達しないというケースは少なくありません。その場合の賃金計算の原則は控除（月給者であれば本来受けとれるだけの給与額から働いていない時間数分を差し引く）するのが原則です。これは、
        <span>ノーワークノーペイの原則</span>
        といい、働いた分の給与のみが支払われるという前提があるからです。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">実務上の対策</h1>
      <p>
        所定労働時間に満たない場合、働いていない分の給与支払い義務はありません。言い換えると、給与計算事務担当者としては、その時間数を当該期間が含まれる月の給与計算時において、
        <span>所定労働時間に達していない時間分を控除しなければなりません</span>
        ので、事務は煩雑化します。この問題は時給での労働契約を締結している従業員に対しては問題となりませんが、月給での労働契約を締結している従業員に対して問題となります。具体的な対策としては働いていない時間帯に「半日単位の有給休暇」や「時間単位の有給休暇」の申請があった場合は賃金を控除することができなくなりますので、給与にて控除をかける必要がなくなります。なぜなら、有給休暇の申請があると、給与を保障した状態で働く義務を免除するからです。
        <br />
        <br />
        半日単位の有給休暇と時間単位の有給休暇では、遅刻や早退に広く対応できるため、ニーズがあるように思われますが、注意が必要です。
        <span>時間単位の有給休暇は労使協定の締結が必要となります。</span>
        ただし、締結後に所轄労働基準監督署への届け出は不要とされています。
        <br />
        <br />
        他の注意点として、
        <span>
          労働基準法上、有給休暇の本来の目的は心身のリフレッシュや次の労働日に向けて英気を養ってもらうこと
        </span>
        ですので、一日の特定の時間帯は指揮命令下に置かれるという状況を勘案すると、時間単位の有給休暇はその目的を達成するには十分とは言えません。そこで、時間単位の有給休暇は1年度につき5労働日までと決められています。
        <br />
        <br />
        また、2019年4月1日以降は年10日以上有給休暇を付与される従業員に対して、当該付与日から１年以内に5日の取得が義務化されています。この5日の中には半日単位の有給休暇を消化した場合は0.5日としてカウントされますが、時間単位の有給休暇はカウントされません。すなわち、法違反回避のためには、原則の暦日単位の有給休暇か半日単位の有給休暇のいずれかしか選択できないということです。
      </p>
      <h1 id="toc4">最後に</h1>
      <p>
        そもそも恒常的に所定労働時間に満たない日が続く従業員は何らかの事情を抱えている可能性が高いと言えます。一例として家族の介護問題や転職活動中であることも想定されます。実務上の対策に挙げた時間単位の有給休暇は言うまでもなく「根本的な解決策」とはなっていません。有給休暇の取得理由は不問とされていますが
        <span>
          有給休暇の行使がない欠勤についてはどのような理由があってそのような状態になっているのか面談の機会を設定することの
        </span>
        方が長期的な解決のための近道と言えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0055
